import {useMemo, useRef} from 'react'

export function useMoo<T>(fn: () => T, deps: unknown[]): T {
  const depsRef = useRef(deps)

  if (
    depsRef.current.length !== deps.length ||
    depsRef.current.some((v, i) => v !== deps[i])
  ) {
    depsRef.current = deps
  }

  return useMemo(fn, [depsRef.current])
}
