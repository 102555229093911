import {Icon} from 'quickstart/components/content/Icon'
import {Stack} from 'quickstart/components/layout/Stack'
import {transient} from 'quickstart/styled-components/system'
import {UnprefixTransientProps} from 'quickstart/types'
import {wrapChildren} from 'quickstart/utils'
import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  isValidElement,
  ReactNode,
} from 'react'
import {partitionProps} from 'tizra'
import * as S from './styles'

type ButtonProps = UnprefixTransientProps<
  Omit<ComponentProps<typeof S.Button>, 'children'>
> & {
  children?: ReactNode
  icon?: ComponentProps<typeof Icon>['icon']
}

const _Button = forwardRef<any, ButtonProps>(
  (
    {
      children,
      disabled,
      fluid = false,
      hoverable = true,
      icon,
      size = 'lg',
      variant = 'primary',
      ...rest
    },
    ref,
  ) => (
    <S.Button
      disabled={disabled}
      {...transient({
        fluid,
        hoverable,
        size,
        variant,
      })}
      {...rest}
      ref={ref}
    >
      {icon && <Icon icon={icon} />}
      {wrapChildren(children)}
    </S.Button>
  ),
)

type GroupButtonProps = {
  disabled?: boolean
  hoverable?: boolean
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
}

export type ButtonGroupProps = ComponentProps<typeof Stack> & GroupButtonProps

const Group = forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({children, ...props}, ref) => {
    const [groupProps, rest] = partitionProps(
      ['disabled', 'hoverable', 'size', 'variant'],
      props,
    )

    const childArray = Children.toArray(children)
    const primaryIndex = childArray.findIndex(x => isValidElement(x))

    return (
      <Stack row spacing="1rem" ref={ref} {...rest}>
        {childArray.map((child, i) =>
          isValidElement(child) ?
            cloneElement(child, {
              variant: i === primaryIndex ? 'primary' : 'secondary',
              ...groupProps,
              ...child.props,
            })
          : child,
        )}
      </Stack>
    )
  },
)

export const Button = Object.assign(_Button, {Group})
