import * as AK from '@ariakit/react'
import {Icon} from 'quickstart/components/content/Icon'
import {Image} from 'quickstart/components/content/Image'
import {Link} from 'quickstart/components/content/Link'
import {Text} from 'quickstart/components/content/Text'
import {Container} from 'quickstart/components/layout/Container'
import {NavLink, NavLinkProps} from 'quickstart/components/layout/NavLink'
import {Stack} from 'quickstart/components/layout/Stack'
import {ProfileMenu} from 'quickstart/components/tizra/ProfileMenu'
import {QuickSearch} from 'quickstart/components/tizra/QuickSearch'
import {SearchTermsInput} from 'quickstart/components/tizra/SearchTermsInput'
import {useApi, useIsomorphicLayoutEffect} from 'quickstart/hooks'
import {space} from 'quickstart/theme'
import {tid} from 'quickstart/utils'
import {ComponentProps, forwardRef} from 'react'
import * as Final from 'react-final-form'
import {UseProfileItemsProps, useNavItems, useProfileItems} from './hooks'
import * as S from './styles'

type MenuItemProps = ComponentProps<typeof NavLink>

const MenuItem = (props: MenuItemProps) => (
  <Text
    variant="h6"
    as="div"
    style={{
      paddingLeft: space.lg,
      paddingRight: space.lg,
    }}
  >
    <NavLink {...props} />
  </Text>
)

export interface NavProps {
  bookshelfConfig: UseProfileItemsProps['bookshelfConfig']
  logoLink: string
  logoUrl: string
  quickSearchPlaceholder: string
  includeCart: boolean
  includeSignIn: boolean
  nestedNavConfig: {items: NavLinkProps[]; profileItems: NavLinkProps[]}
  // old stuff from before nestedNavConfig
  links?: never
  profileMenu?: never
}

export const Nav = forwardRef<any, NavProps>(
  (
    {
      bookshelfConfig,
      logoLink,
      logoUrl,
      quickSearchPlaceholder,
      includeCart,
      includeSignIn,
      nestedNavConfig,
      ...props
    },
    ref,
  ) => {
    const disclosure = AK.useDisclosureStore()
    const open = disclosure.useState('open')

    const {profileName, profileItems} = useProfileItems({
      ...nestedNavConfig,
      bookshelfConfig,
      includeSignIn,
    })

    const {items: navItems} = useNavItems(nestedNavConfig)

    const {data: cart} = useApi.cart()
    const notifyCart = !!cart?.length

    useIsomorphicLayoutEffect(() => {
      if (open) {
        const saved = document.body.style.overflow
        window.scrollTo(0, 0)
        document.body.style.overflow = 'hidden'
        return () => {
          document.body.style.overflow = saved
        }
      }
    }, [open])

    return (
      <S.Nav {...props} ref={ref}>
        <S.LogoRow>
          <S.Logo>
            <Link href={logoLink} variant="unstyled">
              <Image loading="eager" src={logoUrl} />
            </Link>
          </S.Logo>

          <S.Mobile $display="flex">
            {!!includeCart && (
              // @ts-expect-error as=foo yikes
              <S.NavButton as={NavLink} type="link" href="/cart">
                <Icon icon="cart" size="100%" notify={notifyCart} />
              </S.NavButton>
            )}
            <AK.Disclosure store={disclosure}>
              {props => (
                <S.NavButton
                  right
                  icon={open ? 'close' : 'burger'}
                  {...tid('burger')}
                  {...props}
                />
              )}
            </AK.Disclosure>
          </S.Mobile>

          <S.Desktop style={{flex: '0 0 30rem'}}>
            <QuickSearch>
              {(state: any) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{flex: '1 0 auto'}}>
                    <QuickSearch.Input
                      {...state}
                      placeholder={quickSearchPlaceholder || undefined}
                      size="md"
                    />
                  </div>
                  <div>
                    <QuickSearch.Button {...state}>
                      {(props: any) => (
                        <S.NavButton
                          {...props}
                          icon="search"
                          height="48px"
                          width="54px"
                          py="10px"
                          px="10px"
                        />
                      )}
                    </QuickSearch.Button>
                  </div>
                  {!!includeCart && (
                    // @ts-expect-error NavButton wants icon=string
                    <S.NavButton
                      as={NavLink}
                      height="48px"
                      width="54px"
                      py="10px"
                      px="10px"
                      type="link"
                      href="/cart"
                    >
                      <Icon icon="cart" size="100%" notify={notifyCart} />
                    </S.NavButton>
                  )}
                </div>
              )}
            </QuickSearch>
          </S.Desktop>
        </S.LogoRow>

        <AK.DisclosureContent store={disclosure}>
          {(props: ComponentProps<typeof S.MenuHolder>) => (
            <S.MenuHolder {...props} {...tid('mobile-menu')}>
              <S.Menu>
                <Container>
                  <Stack divided spacing="lg">
                    <div />
                    {navItems.map((props, i) => (
                      <MenuItem key={`nav.${i}`} {...props} />
                    ))}
                    {!!includeCart && (
                      <MenuItem type="link" label="Cart" href="/cart" />
                    )}
                    {profileItems
                      .filter(item => item.type !== 'separator')
                      .map((props, i) => (
                        <MenuItem key={`profile.${i}`} {...props} />
                      ))}
                    <div />
                  </Stack>
                </Container>
              </S.Menu>
            </S.MenuHolder>
          )}
        </AK.DisclosureContent>

        <S.SearchRow>
          <S.SearchRowItems>
            <QuickSearch>
              {(state: any) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{flex: '1 0 0', padding: '10px 0'}}>
                    <QuickSearch.Input {...state}>
                      {({name, type, ...quickInputProps}: any) => (
                        <Final.Field name={name} type={type}>
                          {({input: finalInputProps}) => (
                            <SearchTermsInput
                              size={'md' /* 44px tall */}
                              placeholder={quickSearchPlaceholder || undefined}
                              width="100%"
                              {...quickInputProps}
                              style={{
                                borderWidth: '1px', // XXX clean up
                                ...quickInputProps.style,
                              }}
                              {...finalInputProps}
                            />
                          )}
                        </Final.Field>
                      )}
                    </QuickSearch.Input>
                  </div>
                  <div>
                    <QuickSearch.Button {...state}>
                      {(props: any) => (
                        <S.NavButton {...props} right icon="search" />
                      )}
                    </QuickSearch.Button>
                  </div>
                </div>
              )}
            </QuickSearch>
          </S.SearchRowItems>
        </S.SearchRow>

        <S.NavRow {...tid('desktop-menu')}>
          <NavLink.Group>
            {navItems.map((props, i) => (
              <NavLink key={i} {...props} />
            ))}
          </NavLink.Group>
          <NavLink.Group>
            <ProfileMenu profileName={profileName} items={profileItems} />
          </NavLink.Group>
        </S.NavRow>

        <S.NavDivider />
      </S.Nav>
    )
  },
)
