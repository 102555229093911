import {useQueryClient} from '@tanstack/react-query'
import {useMemo, useState} from 'react'
import {Falsy, SearchTypes, isDisjointFrom, logger, truthy} from 'tizra'
import {useApi, useApis} from './useApi'
import * as R from 'rambdax'
import {JsonValue} from 'type-fest'
import {useMoo} from './useMoo'

const LOG = logger('useTypeDefs')

let logCounter = 0

type SearchTypesParams = Parameters<typeof useApi.searchTypes>[0]

type SearchTypesQuery = ReturnType<typeof useApi.searchTypes>

const arr = (x: Set<string> | Array<string> | Falsy): string[] =>
  !x ? [] : Array.from(x instanceof Set ? x : new Set(x)).sort()

const useStable = <T extends JsonValue>(x: T) =>
  useMemo<T>(() => x, [JSON.stringify(x)]) // eslint-disable react-hooks/exhaustive-deps

type SearchTypesCall =
  | ['searchTypes', SearchTypesParams]
  | [
      'searchTypes',
      SearchTypesParams,
      {
        initialData: SearchTypes
        initialDataUpdatedAt: number
      },
    ]

const addAll = <T>(a: Set<T>, b: Set<any>): Set<T> => {
  for (const bb of b) a.add(bb)
  return a
}

const removeAll = <T>(a: Set<T>, b: Set<any>): Set<T> => {
  for (const bb of b) a.delete(bb)
  return a
}

export const useTypeDefs = (_metaTypes?: string[] | Falsy) => {
  const [log] = useState(() => LOG.logger(`${logCounter++}`))
  const metaTypes = useStable(_metaTypes || [])
  const queryClient = useQueryClient()

  // Find or create overlapping queries in priority order:
  //
  // 1. active queries
  // 2. combined query of all non-active queries
  // 3. additional query for remaining
  //
  // This ordering avoids repeating active queries, while step 2 allows
  // subsequent pages to gradually request the full set as a single request.

  const calls = useMemo<SearchTypesCall[]>(() => {
    const calls: SearchTypesCall[] = []
    const remaining = new Set(metaTypes)

    if (!remaining.size) return calls

    const cache = queryClient.getQueryCache()

    const cached = R.piped(
      cache.findAll({queryKey: ['searchTypes']}),
      R.map(query => {
        const params = query.queryKey[1] as SearchTypesParams
        return {
          query,
          params,
          metaTypes: new Set((params && params.metaType) || []),
        }
      }),
    )

    const {
      active = [],
      pending = [],
      inactive = [],
      rest: _rest = [],
    } = R.piped(
      cached,
      R.sortBy(({query}) => query.state.dataUpdatedAt), // esp for combining inactive
      R.groupBy(({query}) =>
        query.isActive() ? 'active'
          // It seems like react-query goes through a pending-but-not-active
          // step that can cause us to fall through and duplicate queries if we
          // don't check. This only seems to happen in production scenarios, not
          // under test that I've been able to replicate. :-/
        : query.state.status === 'pending' ? 'pending'
          // We only want to consider inactive queries with data that we can
          // reuse as initialData.
        : query.state.data !== undefined ? 'inactive'
        : 'rest',
      ),
    )

    log.debug?.(
      'cached is',
      cached.map(({query, ...entry}) => ({
        ...entry,
        active: query.isActive(),
        stale: query.isStale(),
        query: {...query},
      })),
      {active, pending, inactive, _rest},
    )

    // 1. active queries
    log.debug?.('checking active', arr(remaining))
    for (const {metaTypes, params} of [...active, ...pending]) {
      if (!isDisjointFrom(metaTypes, remaining)) {
        calls.push(['searchTypes', params])
        removeAll(remaining, metaTypes)
        if (!remaining.size) return calls
      }
    }

    // 2. combined query of all non-active queries with data
    log.debug?.('checking inactive', arr(remaining))
    if (inactive.some(({metaTypes}) => !isDisjointFrom(metaTypes, remaining))) {
      if (inactive.length === 1) {
        log.debug?.('found single inactive')
        const {params, metaTypes} = inactive[0]
        calls.push(['searchTypes', params])
        removeAll(remaining, metaTypes)
      } else {
        log.debug?.('found multiple inactive', inactive.length)
        const {metaTypes, initialData, initialDataUpdatedAt} = R.piped(
          inactive,
          R.reduce(
            (acc, {query, metaTypes}) => {
              addAll(acc.metaTypes, metaTypes)
              Object.assign(acc.initialData, query.state.data as SearchTypes)
              acc.initialDataUpdatedAt ||= query.state.dataUpdatedAt
              return acc
            },
            {
              metaTypes: new Set<string>(),
              initialData: {} as SearchTypes,
              initialDataUpdatedAt: 0,
            },
          ),
        )
        calls.push([
          'searchTypes',
          {metaType: arr(metaTypes)},
          {initialData, initialDataUpdatedAt},
        ])
        log.debug?.('combined call', calls[calls.length - 1])
        removeAll(remaining, metaTypes)
      }
      if (!remaining.size) return calls
    } else if (inactive.length) {
      log.debug?.("there are inactives, but they don't overlap")
    }

    // 3. additional query for remaining
    log.debug?.('making additional query', arr(remaining))
    calls.push(['searchTypes', {metaType: arr(remaining)}])

    return calls
  }, [log, metaTypes, queryClient])

  const queries = useApis(calls) as SearchTypesQuery[]

  const datas = queries.map(q => q.data)

  const combined = useMoo(() => {
    if (!metaTypes.length) return undefined
    if (!datas.length) {
      log.error(`metaTypes length is ${metaTypes.length} but empty datas`)
      return undefined
    }
    if (!datas.every(truthy)) {
      log.debug?.(`truthy datas ${datas.filter(truthy).length}/${datas.length}`)
      return undefined
    }
    const combined = datas.reduce(
      (comb: SearchTypes, d) => Object.assign(comb, d),
      {},
    )
    const picked = R.pick(metaTypes, combined)
    return picked
  }, [metaTypes, datas.length, ...datas])

  return combined
}
