import {RefCallback} from 'react'
import {logger, meta, MetaObject, OEmbed, TocEntry} from 'tizra'
import {useApi} from './useApi'
import {useVisible} from './useVisible'
import {SetRequired} from 'type-fest'

const log = logger('useOEmbed')

interface UseOEmbedProps {
  enabled?: boolean
  loading?: 'eager' | 'lazy'
  metaObj?: MetaObject | TocEntry
}

export type UseOEmbedReturn =
  | {
      status: 'error'
      data?: undefined
      isError: true
      isHtml?: false
      isPending?: false
      isThumb?: false
    }
  | {
      status: 'pending'
      data?: undefined
      isError?: false
      isHtml?: false
      isPending: true
      isThumb?: false
    }
  | {
      status: 'success'
      data: SetRequired<OEmbed, 'html'>
      isError?: false
      isHtml: true
      isPending?: false
      isThumb?: false
    }
  | {
      status: 'success'
      data: SetRequired<OEmbed, 'thumbnail_url'>
      isError?: false
      isHtml?: false
      isPending?: false
      isThumb: true
    }
  | {
      status: 'success'
      data: SetRequired<OEmbed, 'html' | 'thumbnail_url'>
      isError?: false
      isHtml: true
      isPending?: false
      isThumb?: true
    }

export const useOEmbed = ({
  enabled = true,
  metaObj,
  loading,
}: UseOEmbedProps): [UseOEmbedReturn, RefCallback<Element>] => {
  const hasOEmbed = meta.hasOEmbed(metaObj)
  const tizraId = (metaObj && 'tizraId' in metaObj && metaObj.tizraId) || ''
  const [visible, visRef] = useVisible({
    enabled: enabled && loading !== 'eager',
  })

  const r = useApi.oEmbedProxy(
    enabled &&
      hasOEmbed &&
      tizraId &&
      (visible || loading === 'eager') && {tizraId, width: 900},
  )

  const isHtml = !!r.data?.html
  const isThumb = !!r.data?.thumbnail_url
  const isError =
    hasOEmbed === false || r.isError || (r.data && !isHtml && !isThumb)
  const isPending = !isHtml && !isThumb && !isError
  const status =
    isHtml || isThumb ? 'success'
    : isError ? 'error'
    : 'pending'

  return [
    {
      status,
      data: r.data,
      isHtml,
      isThumb,
      isError,
      isPending,
    } as UseOEmbedReturn,
    visRef,
  ]
}
