import * as R from 'rambdax'
import {deepMerge} from 'tizra/deepMerge'
import {logger} from 'tizra/log'
import {
  LiteralToPrimitiveDeep,
  PartialDeep,
  SetRequired,
  ValueOf,
} from 'type-fest'
import {BP, bps, breakpoints} from './breakpoints'
import {toRem} from './utils'
import {Weight, cssVars, weights} from './vars'

const log = logger('theme/typography')

const siteContainer = '86rem'

// This is consumed by components/layout/Container/styles.tsx
export const containers = {
  default: {
    // This is the ACTUAL WIDTH of the content, since it applies to the
    // InnerContainer, and the padding below applies to the OuterContainer.
    maxWidths: {
      xs: siteContainer,
    },
    // Note that some other components refer to these theme values, especially
    // for full-bleed buttons that want to have the same padding on each side.
    paddings: {
      xs: '1rem',
      sm: '1.5rem',
      md: '2rem',
    },
  },
}

// This is consumed by components/layout/TextContainer/styles.tsx
//
// These maxWidths correspond to type sizes specified for various breakpoints in
// the TypographyTable below — ideally limiting line lengths to around 75 chars
// for optimal reading.
//
// If you adjust sizes for textMd, then you should update TextContainers
// maxWidths as well.
export const textContainers = {
  default: {
    maxWidths: {
      sm: '560px',
      xl: '600px',
      xxl: '720px',
    },
  },
  wider: {
    maxWidths: {
      // This used to be 75% but that doesn't work with flex-direction:
      // column, so use something that is approximately the same thing.
      md: `min(75vw, .75 * ${siteContainer})`,
    },
  },
  none: {
    maxWidths: {},
  },
}

// This list must be kept in sync with the typography table.
const variants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'textSm',
  'textMd',
  'html',
  'htmlWider',
  'listMd',
  'button',
  'form',
  'formMeta',
  'tag',
  'sectionHead',
  'sectionHeadTight',
  'nav',
  'navMeta',
  'subnavHead',
  'subnav',
  'facetHead',
  'cardHead',
  'cardSnippet',
  'cardMeta',
  'cardCta',
  'summaryHead',
  'summarySubhead',
  'summarySnippet',
  'summaryMeta',
  'headlineHead',
  'tileLabel',
  'tileHeadSm',
  'tileHeadMd',
  'tileHeadLg',
  'tileMeta',
  'tileDescription',
  'tileCta',
  'digestHeadSm',
  'digestHeadMd',
  'digestHeadLg',
  'digestSnippetLg',
  'digestSnippetMd',
  'digestSnippetSm',
  'attachment',
  'modalHead',
  'imageCaption',
] as const
export type Variant = (typeof variants)[number]

const props = ['size', 'height', 'margin', 'weight'] as const
type Prop = (typeof props)[number]

type Props = {
  size: number
  height: number
  margin: number
  weight: Weight
}
type ValueOrBreakpoints<T> = T | SetRequired<{[B in BP | '_']?: T}, '_'>
type TypographyTable = {
  [V in Variant]: {[K in keyof Props]: ValueOrBreakpoints<Props[K]>}
}

export type Rhythm = 'layout' | 'prose'

export const makeTypographyTable = (s: string) => {
  let table = {} as TypographyTable
  const current = {} as {bp: BP | '_'}
  for (let line of s.split('\n')) {
    line = line.replace(/\/\/.*/, '').trimEnd()
    if (!line) {
      continue
    }
    let [bp, v, ...rest] = line.split(/\s+/) as [
      BP | '_', // or undefined
      Variant, // or undefined
      ...string[],
    ]
    if (!bp && !v) {
      log.error('asoiefjaoseifj')
      continue
    }
    bp = current.bp = bp || current.bp
    if (!v) continue
    const spec = {} as PartialDeep<ValueOf<TypographyTable>>
    const fs = rest.find(s => s.includes('/'))
    if (fs) {
      const [size, height] = fs.split('/').map(s => parseFloat(s))
      if (size) {
        spec.size = {[bp]: size}
      }
      if (height) {
        spec.height = {[bp]: height}
      }
    }
    const m = rest.find(s => /^(?:(?:\d*[.])?\d+)$/.test(s))
    if (m) {
      const margin = parseFloat(m)
      if (margin || margin === 0) {
        spec.margin = {[bp]: margin}
      }
    }
    const w = rest.find(s => weights.includes(s as any))
    if (w) {
      spec.weight = {[bp]: w}
    }
    table[v] = deepMerge(table[v] ?? {}, {unknown: 'throw'})(spec)
  }
  return table
}

const T = makeTypographyTable(`
_ // base
  h1          32/34     0.5 bold
  h2          28/32     0.5 bold
  h3          24/28     0.5 bold
  h4          20/26     0.5 bold
  h5          18/24     0.5 bold
  h6          16/22     0.5 bold
  subnavHead  20/24     0.5 bold
  sectionHead 20/24     1   bold
  textSm      14/26     1   regular
  textMd      16/28     1   regular

sm // 568px

md // 768px

lg // 1024px

xl // 1280px
  h1          48/52
  h2          36/40
  h3          32/36
  h4          22/28
  h5          20/26
  h6          18/24
  subnavHead  28/32
  sectionHead 28/32
  textSm      16/30
  textMd      18/32

xxl // 1440px
  h4     24/30
  h5     22/28
  h6     20/26
  textSm 18/34
  textMd 20/36
`)

// prettier-ignore
// font-size, line-height, margin-bottom (ratio of line-height), font-weight
const _TYPOGRAPHY = {
  ...T,
  html:      T.textMd,
  htmlWider: {...T.textMd, height: {_: 28, md: 32, xl: 36, xxl: 40}},
  listMd:    {...T.textMd, margin: 0.5},

  // form typography (includes search facets)
  button:   {size: 16, height: 1, margin: 0, weight: 'bold'},
  form:     {size: 16, height: 1.5, margin: 0.8, weight: 'regular'},
  formMeta: {size: 16, height: 1.5, margin: 0.25, weight: 'regular'},  // also used as margin top
  tag:      {size: 14, height: 1.5, margin: 0, weight: 'bold'},

  // sections
  sectionHeadTight: {...T.sectionHead, margin: 0.5},

  // primary navigation
  nav:        {size: 16, height: 1.5, margin: 0, weight: 'regular'},
  navMeta:    {size: 14, height: 1.5, margin: 0, weight: 'regular'},
  subnav:     {size: 18, height: 1.5, margin: 0, weight: 'regular'},
  facetHead:  T.h6,

  // content navigation
  cardHead:       T.h5,
  cardSnippet:    {...T.textSm, height: 1.5, margin: 0, weight: 'regular'},
  cardMeta:       {...T.textSm, height: 1.5, margin: 0.125, weight: 'regular'},
  cardCta:        {...T.textSm, height: 1.5, margin: 0, weight: 'bold'},
  summaryHead:    T.h5,
  summarySubhead: {...T.textMd, height: 1.5, margin: 0},
  summarySnippet: {...T.textMd, height: 1.5, margin: 0.5, weight: 'light'},
  summaryMeta:    {...T.textSm, height: 1.5, margin: 0},
  headlineHead:   T.h3,
  tileLabel:      {...T.textSm, height: 1.5, margin: 0.125, weight: 'regular'},
  tileHeadSm:     T.h6,
  tileHeadMd:     T.h5,
  tileHeadLg:     T.h4,
  tileMeta:       {...T.textSm, height: 1.5, margin: 0, weight: 'regular'},
  tileDescription:{...T.textSm, height: 1.5, margin: 0, weight: 'regular'},
  tileCta:        {...T.textSm, height: 1.5, margin: 0, weight: 'bold'},
  digestHeadSm:   T.h6,
  digestHeadMd:   T.h5,
  digestHeadLg:   T.h4,
  digestSnippetLg:{...T.textSm, height: 1.5, margin: 0, weight: 'regular'},
  digestSnippetMd:{...T.textSm, height: 1.5, margin: 0, weight: 'regular'},
  digestSnippetSm:{...T.textSm, height: 1.5, margin: 0, weight: 'regular'},

  // attachments list
  attachment:     {...T.textMd, height: 1.5, margin: 0.5},

  // modals
  modalHead:      T.h5,

  // post and static page typography
  imageCaption:   {...T.textSm, height: 20 / 14, margin: 0.25},
}

const TYPOGRAPHY = _TYPOGRAPHY as TypographyTable

export const forTests = {TYPOGRAPHY, variants, props, breakpoints}

export type MQ = `@media (min-width: ${ValueOf<typeof breakpoints>})`

type VariantRules<T> = {[k in Variant]: T & {[k in MQ]?: Partial<T>}}

interface Ui {
  fontSize: string
  lineHeight: string
  fontWeight: string
  [cssVars.lineHeight]: string
  [cssVars.relativeWeights.bolder]: string
  [cssVars.relativeWeights.boldest]: string
}

interface SpaceAfter {
  marginBottom: string
}

interface SpaceBeforeHack {
  marginTop: string
}

const metrics = ['fs', 'lh', 'fw', 'mb', 'b', 'bb'] as const
type Metric = (typeof metrics)[number]
type ModVars = {
  [k in `--eg-${Metric}-xs`]: string
} & {
  [k in `--eg-${Metric}-${BP}`]?: string
}

const getTypography = () => {
  const ui = {} as VariantRules<Ui>
  const spaceAfter = {} as VariantRules<SpaceAfter>
  const spaceBeforeHack = {} as VariantRules<SpaceBeforeHack>
  const modVars = {} as {[k in Variant]: ModVars}

  for (const [variant, spec] of R.toPairs(TYPOGRAPHY)) {
    const rawValues = {} as Props

    // Assign to a theme object, omitting values that haven't changed since the
    // previous breakpoint.
    const lastValues = {
      ui: {} as Partial<Ui>,
      spaceAfter: {} as Partial<SpaceAfter>,
    }
    const assign = (props: {
      name: 'ui' | 'spaceAfter' | 'spaceBeforeHack'
      themeObj:
        | VariantRules<Ui>
        | VariantRules<SpaceAfter>
        | VariantRules<SpaceBeforeHack>
      mq: false | MQ
      values: Partial<Ui> | Partial<SpaceAfter> | Partial<SpaceBeforeHack>
    }) => {
      const name = props.name as 'ui'
      const themeObj = props.themeObj as VariantRules<Ui>
      const {mq} = props
      if (!mq) {
        const values = props.values as Ui
        // Copy base values separately to each, because we'll mutate these
        // differently below.
        themeObj[variant] = {...values}
        lastValues[name] = {...values}
      } else {
        const values = props.values as Partial<Ui>
        const changed = R.filter(
          (v, k) => v !== lastValues[name][k as keyof Ui],
          values,
        ) as Partial<Ui>
        if (!R.isEmpty(changed)) {
          themeObj[variant][mq] = changed
        }
        Object.assign(lastValues[name], values)
      }
    }

    const embolden: {[k in Weight]: Weight} = {
      // HACK: Our "light" and "regular" are the same with Merriweather. The
      // only place we currently use "light" is in summarySnippet, and we want
      // bolding to work there, so force bolding of light to bold.
      light: 'bold',
      regular: 'bold',
      bold: 'black',
      black: 'black',
    }

    modVars[variant] = {} as any
    let lastMetrics = {} as {[k in Metric]?: string}

    for (const bp of ['_', ...bps] as const) {
      const getRawValue = <P extends Prop, R extends Props[P]>(prop: P): R => {
        const r = (rawValues[prop] =
          typeof spec[prop] === 'object' ?
            // @ts-expect-error doesn't seem to narrow to object
            spec[prop][bp] ?? rawValues[prop]
          : spec[prop])
        return r
      }

      const sizeInPixels = getRawValue('size')
      // prettier-ignore
      const fontSize = `calc(${toRem(sizeInPixels)} * var(${cssVars.scales.size}))`

      const rawHeight = getRawValue('height')
      const relativeHeightCalc =
        rawHeight < 4 ? `${rawHeight}` : `(${rawHeight} / ${sizeInPixels})`
      const lineHeight = `calc(${relativeHeightCalc} * var(${cssVars.scales.height}))`
      // Additionally provide absolute height so that components can customize
      // spacing based on it.
      const absHeight = `calc(${lineHeight} * ${fontSize})`

      const relativeMargin = getRawValue('margin')
      const marginBottom =
        relativeMargin === 0 ? '0' : (
          `calc(${fontSize} * ${relativeMargin} * ${relativeHeightCalc} * var(${cssVars.scales.margin}))`
        )

      const weight = getRawValue('weight')
      const fontWeight = `var(${cssVars.weights[weight]})`

      // e.g. if weight is "bold" then bolder is "black"
      const bolder = embolden[weight]
      const boldest = embolden[bolder]

      const mq: false | MQ =
        bp !== '_' && `@media (min-width: ${breakpoints[bp]})`

      assign({
        name: 'ui',
        themeObj: ui,
        mq,
        values: {
          fontSize,
          lineHeight,
          fontWeight,
          // Make a variable for the line-height, so that components can customize
          // spacing based on it.
          [cssVars.lineHeight]: absHeight,
          // Make variables for bolder weights, so components can handle
          // contained <b> or <strong>
          [cssVars.relativeWeights.bolder]: `var(${cssVars.weights[bolder]})`,
          [cssVars.relativeWeights.boldest]: `var(${cssVars.weights[boldest]})`,
        },
      })

      assign({
        name: 'spaceAfter',
        themeObj: spaceAfter,
        mq,
        values: {marginBottom},
      })

      // This is only for .customer-html to separate a paragraph from
      // a preceding div.
      assign({
        name: 'spaceBeforeHack',
        themeObj: spaceBeforeHack,
        mq,
        values: {marginTop: marginBottom},
      })

      // Replacement for all of the above to power CSS modules-based Text
      // component.
      const nextMetrics = {
        fs: fontSize,
        lh: lineHeight,
        fw: fontWeight,
        mb: marginBottom,
        b: `var(${cssVars.weights[bolder]})`,
        bb: `var(${cssVars.weights[boldest]})`,
      } as const satisfies {[k in Metric]: string}
      const bx = bp === '_' ? 'xs' : bp
      metrics.forEach(metric => {
        if (nextMetrics[metric] !== lastMetrics[metric]) {
          modVars[variant][`--eg-${metric}-${bx}`] = nextMetrics[metric]
        }
      })
      lastMetrics = nextMetrics
    }
  }

  const ret = {
    modVars,
    ui,
    spaceAfter,
    spaceBeforeHack,
    prose: deepMerge(ui as VariantRules<Ui & SpaceAfter>)(spaceAfter),
  }

  // I don't fully understand why this cast is necessary, but it changes the
  // typing so that Linaria is okay interpolating without needing "as any"
  return ret as LiteralToPrimitiveDeep<typeof ret>
}

export const typography = getTypography()
